/************************************************/
/*			_colorScheme.scss					*/
/*			Compiles to common.css				*/
/*			Original author: PSJ				*/
/*			Contributers:						*/
/************************************************/
/// <reference path="common.scss" />

/*  Keep your act clean:
	Use the TOC - main sections are marked by a comment box, and written in uppercase,
	Subsections are noted in an uppercase singleline comment,
	subsections are marked with indentation and an angle (>) in the TOC.

	If you are the original frontend owner add your name as the original author

	If you are contributing a large chunk of new code please add your name as a contributer,
	and at the start of any new section or subsection
*/

/************************************************/
/*			TOC									*/
/************************************************/

/*
	COLOR SETUP

	COLOR PALETTE
		> GENERAL
		> TEXT
		> LINKS
		> STOCK STATUS
		> BUTTONS
		> MODALS
		> SPOTS
		> SPOT TABLE
		> DOTTED TABLE
		> FORMS
		> USER LOGIN
		> HEADER
		> QUICKSEARCH
		> NAVIGATION
		> SUBNAVIGATION
		> MEGAMENU
		> CHOOSE CART
		> MINICART
		> FOOTER
		> PRODUCT CATALOGUE
		> PRODUCT BAR
		> TOOLBAR
		> PRODUCT LIST
		> PRODUCT PRICE TABLE
		> PRODUCT SPOTS
		> PRODUCT SPOT HEADING
		> PRODUCT CAMPAIGNS
		> USER PROFILE MENU
		> NEWS BOX
		> NEWS LIST
		> QUICKLINKS
		> PRODUCT DETAILS
		> TOP SLIDER
		> TOP CAMPAIGN
		> BREADCRUMB
		> CHECKOUT
        > PAYMENT
		> ADDRESS LIST
		> QUOTE DETAILS
		> ADD PRODUCT TOOLBAR
		> WORKSHEET DETAILS
		> SENT ORDERS
		> TABLE LISTS
		> SITEMAP
		> FILTER MENU
		> TOOLTIPS
		> DATEPICKER
		> ADDTO
		> PUBLICATIONS
		> PAGINATION
		> ACCORDION
		> FAVORITES ACCORDION
		> TOASTR
		> SEARCH
		> DEALER
		> PAGEMULTISPOT
		> WFFM

END TOC */

/************************************************/
/*			COLOR SETUP							*/
/************************************************/

$colWhite: #fff;
$colBlack: #000;
$colRed: #990000;
$colRed01: #fac0c0;
$colRed02: #f0d9d9;
$colBlue01: #5a6e82;
$colBlue02: #324c59;
$colBlue03: #95a6b5;
$colBlue04: #b9c4ce;
$colBlue05: #cdd4dc;
$colBlue06: #e2e6ea;
$colBlue07: #c2e5fa;
$colGreen: #28a000;
$colOrange: #ff6100;
$colGray01: #e0e0e0;
$colGray02: #f4f4f4;
$colGray03: #ccc;
$colGray04: #f1f1f5;
$colGray05: #b2b2b2;
$colYellow: #e6c700;
$colYellow01: #fbdda0;
$colYellow02: #fce9b6;
$colDarkGray: #505050;
$colLightGray: #969696;
$colWhiteGray: #fcfcfc; /* Not part of colorscheme */
$colArrowBg: $colWhiteGray; /* Only used to match bgPattern base64 colors */
$colorBloodOrange: #c80000;
$colorOffWhite: #f3f1ec;
$colorBrownishGray: #606060;
$colorPowderBlue: #d6dbde;
$color__black: #242424;
$color__pinkish-gray: #c9beb0;

/************************************************/
/*			COLOR PALETTE						*/
/************************************************/

/* NEW ReSKINNING 2019/2020 COLORS */
$accentColorNew: #c80000;
$offWhite: #f3f1ec;
$whiteout: #ffffff;
$powderBlue: #d6dbde;
$newBlack: #242424;
$transparentLightNeutral: rgba(0, 0, 0, 0.2);
$lowkey: #606060;
$pinkishGray: #c9beb0;

/* GENERAL */
$baseBgColor: $colWhite;
$blueBorderBottomColor: $colBlue02;
$blueBorderSplitLineColor: $colBlue01;
$grayBorderSplitLineColor: $colGray01;
$promptBoxBgColor: $colGray02;
$promptBoxBorderColor: $colBlack;
$grayBorderColor: $colGray01;
$quoteTextColor: $colRed;
$dateSplitBorderColor: $colGray03;
$teaserTextColor: $colRed;
$quoteBorderColor: $colBlue01;
$imageTextColor: $colLightGray;

/* TEXT */
$baseTextColor: $colDarkGray;
$pageTitleTextColor: $colBlue02;
$pageTitleLinkColor: $colBlue02;
$pageTitleMutedTextColor: $colGray05;

/* LINKS */
$baseLinkColor: $colBlue02;
$baseLinkColorHover: $colRed;

/* STOCK STATUS */
$inStockTextColor: $colGreen;
$outOfStockTextColor: $colRed;

/* BUTTONS */
$buttonBgColor: $colBlue01;
$buttonBgHoverColor: $colBlue03;
$buttonTextColor: $colWhite;
$buttonSecondaryBgColor: $colGreen;
$buttonSecondaryBgHoverColor: $colGreen;
$buttonSecondaryTextColor: $colWhite;
$buttonTertiaryBgColor: $colBlue03;
$buttonTertiaryBgHoverColor: $colBlue03;
$buttonTertiaryTextColor: $colWhite;
$buttonDangerousBgColor: $colRed;
$buttonDangerousBgHoverColor: $colRed01;
$buttonDangerousTextColor: $colWhite;

$buttonWhiteBgColor: $colWhite;
$buttonWhiteBgHoverColor: $colLightGray;
$buttonWhiteTextColor: $baseTextColor;
$buttonWhiteBorderColor: $colGray05;

/* MODALS */
$modalOverlayBgColor: $colBlack;
$modalBgColor: $colWhite;
$modalCurrentTextColor: $colWhite;
$modalArrowBgColor: transparent;
$modalButtonBgHoverColor: $colBlue03;

/* SPOTS */
$spotBgColor: $colWhite;
$spotBorderColor: $colGray01;
$spotVerticalBorderColor: $colBlue01;
$spotDottedBorderColor: $colGray03;
$spotBottomLinkColor: $colBlue02;
$spotHeaderColor: $colBlue02;
$spotBlueHeaderColor: $colWhite;
$spotBlueHeaderBgColor: $colBlue03;
$spotDarkBlueHeaderColor: $colWhite;
$spotDarkBlueHeaderBgColor: $colBlue01;
$spotGreyHeaderBgColor: $colGray02;
$spotLightGreyBgColor: $colGray02;

/* SPOT TABLE */
$spotTableTextColor: $colBlack;
$spotTableLinkColor: $colBlack;
$spotTableBorderColor: $colGray03;

/* DOTTED TABLE */
$dottedTableBorderColor: $colGray03;

/* FORMS */
$formBgColor: $colWhite;
$formBorderColor: $colGray05;
$formValidBorderColor: $colGray05;
$formInvalidBorderColor: $colRed;
$formErrorTextColor: $colRed;

/* USER LOGIN */
$userLoginBgColor: $colBlue01;
$userLoginTitleColor: $colWhite;
$userLoginTextColor: $colWhite;
$userLoginLinksColor: $colWhite;

/* HEADER */
$headerTopBorderColor: $colBlue02;
$headerBottomBorderColor: $colGray03;
$headerBgColor: $colBlue02;
$headerTextColor: $colWhite;
$userMenuBgColor: $colBlue02;
$userMenuTextColor: $colWhite;
$userMenuSplitColor: $colWhite;
$userMenuLinkColor: $colWhite;
$userMenuLinkHoverColor: $colBlue04;
$userMenuInboxNumberBgColor: $colBlue06;
$userMenuInboxNumberTextColor: $colDarkGray;
$searchButtonBgColor: $colGray03;
$searchOptionsBgColor: $colGray04;
$searchOptionsBorderColor: $colGray03;
$searchOptionsTextColor: $colLightGray;
$searchOptionsOptionBgColor: $colWhite;
$searchOptionsOptionTextColor: $colLightGray;
$searchOptionsOptionMainTextColor: $colDarkGray;
$searchOptionsCrumbTextColor: $colLightGray;

/* QUICKSEARCH */
$quickSearchBgColor: $colWhite;
$quickSearchBorderColor: $colGray01;
$quickSearchHeaderTextColor: $colBlue02;
$quickSearchRowImgBgColor: $colWhite;
$quickSearchRowBorderColor: $colGray01;
$quickSearchRowSelectedBorderColor: $colBlue02;
$quickSearchRowEvenBgColor: $colWhite;
$quickSearchRowOddBgColor: $colGray02;
$quickSearchRowTypeColor: $colLightGray;
$quickSearchRowTextColor: $colBlack;
$quickSearchArrowBorderColor: $colGray05;
$quickSearchArrowUpColor: $colWhite;
$quickSearchListTextColor: $colBlack;
$quickSearchSplitColor: $colGray05;

/* NAVIGATION */
$navSplitColor: $colGray03;
$navLinkColor: $colWhite;
$navLinkHoverColor: $colBlue04;
$navLinkSelectedColor: $colBlue03;

/* SUBNAVIGATION */
$navSubBackground: $colWhite;
$navSubHeaderBackground: $colGray04;
$navSubHeaderHeadingTextColor: $colBlack;
$navSubLinkBorder: $colGray03;
$navSubLinkTextColor: $colBlue02;
$navSubLinkColor: $colBlue02;
$navSubLinkColorHover: $colRed;
$navSubLinkActiveTextColor: $colBlue02;
$navSubLinkActiveBackground: transparent;
$navSubLinkActiveBackgroundHover: transparent;
$navSubLinkCurrentTextColor: $colRed;

/* MEGAMENU */
$megaMenuBorderColor: $colGray01;
$megaMenuListSplitColor: $colGray03;
$megaMenuLinkColor: $colDarkGray;
$megaMenuHoverLinkColor: $colBlue01;
$megaMenuImageBorderColor: $colGray01;
$megaMenuSubSectionLoadingColor: $colGray03;

/* CHOOSE CART */
$chooseCartTitleColor: $colBlue02;
$chooseCartHeaderColor: $navLinkColor;
$chooseCartHeaderHoverColor: $navLinkHoverColor;
$chooseCartTextColor: $colBlue02;
$chooseCartActiveTextColor: $colWhite;
$chooseCartBorderColor: $colGray01;
$chooseCartBgColor: $colWhite;
$chooseCartActiveBgColor: $colGreen;
$chooseCartWrapperBorderColor: $colGray01;
$chooseCartArrowUpColor: $colArrowBg;
$chooseCartArrowUpBorderColor: $colGray03;
$chooseCartRowBorderColor: $colGray01;
$chooseCartRowOddBgColor: $colWhite;
$chooseCartRowEvenBgColor: $colGray02;
$chooseCartRowActiveBgColor: $colBlue06;
$chooseCartRowTextColor: $colBlue02;
$chooseCartRowActiveTextColor: $colGreen;
$chooseCartBaskedHeaderColor: $colGray05;
$chooseCartAmountBgColor: $colGray05;
$chooseCartAmountTextColor: $colWhite;
$chooseCartAmountActiveBgColor: $colGreen;
$chooseCartShowAllTextColor: $colDarkGray;

/* MINICART */
$miniCartHeaderColor: $navLinkColor;
$miniCartAmountBgColor: $colGray05;
$miniCartAmountActiveBgColor: $colGreen;
$miniCartAmountTextColor: $colWhite;
$miniCartHeaderHoverColor: $navLinkHoverColor;
$miniCartBorderColor: $colGray01;
$miniCartRowImgBgColor: $colWhite;
$miniCartRowEvenBgColor: $colGray02;
$miniCartRowOddBgColor: $colWhite;
$miniCartRowBorderColor: $colGray01;
$miniCartRowTypeColor: $colLightGray;
$miniCartRowTextColor: $colBlack;
$miniCartRowAmountColor: $colBlack;
$miniCartTitleColor: $colBlue02;
$miniCartArrowUpColor: $colArrowBg;
$miniCartArrowUpBorderColor: $colGray03;

/* FOOTER */
$footerTopBorderColor: $colBlue02;
$footerBottomBorderColor: $colGray01;
$footerBottomBgColor: $colWhite;
$footerTabletSplitColor: $colGray03;
$footerHeaderTextColor: $colDarkGray;
$footerLinkColor: $colLightGray;
$footerLinkHoverColor: $colBlue02;
$footerCopyrightColor: $colGray05;
$footerShareColor: $colGray05;
$footerSectionLinkBackground: $colBlue03;
$footerSectionLinkColor: $colWhite;

/* PRODUCT CATALOGUE */
$productCatalogueBgColor: $colGray02;
$productCatalogueBorderColor: $colGray01;
$productCatalogueImageBgColor: $colWhite;
$productCatalogueTextBgColor: $colGray02;
$productCatalogueTextHoverBgColor: $colBlue05;
$productCatalogueTextColor: $colDarkGray;
$productCatalogueExtraTextColor: $colLightGray;
$productCatalogueBrandTextColor: $colLightGray;
$productCatalogueBeforePriceColor: $colRed;
$productCatalogueOfferBgColor: $colRed;
$productCatalogueOfferTxtColor: $colWhite;
$productCatalogueOutletBgColor: $colBlue01;
$productCatalogueOutletTxtColor: $colWhite;
$productCatalogueFormel1000BgColor: $colBlue01; //TODO: Offer, Outlet & Formel 1000 should all only be defined once. IMHO preferably under product details.
$productCatalogueFormel1000TxtColor: $colWhite;
$productCatalogueDiscontinuedBgColor: $colLightGray;
$productCatalogueDiscontinuedTxtColor: $colRed;
$productCatalogueDeleteBgColor: $colRed;
$productCatalogueDeleteTxtColor: $colWhite;

/* PRODUCT BAR */
$productBarHeaderColor: $colBlue02;
$productBarTextColor: $colDarkGray;
$productBarBoxBgColor: $colGray02;
$productBarBoxBorderColor: $colGray01;
$productBarViewArrowColor: $colGray05;

/* TOOLBAR */
$toolbarTextColor: $colDarkGray;
$toolbarTextHoverColor: $colBlue02;
$listViewBorderColor: $colGray05;

/* PRODUCT LIST */
$productItemHeaderBgColor: $colBlue06;
$productItemHeaderBorderColor: $colGray01;
$productListItemLinkColor: $colBlue02;
$productListItemBgColor: $colGray02;
$productListItemBorderColor: $colGray01;
$productListItemSplitBorderColor: $colGray03;
$productListImgBgColor: $colWhite;
$productListImgButtonsBgColor: $colWhite;
$productListDimedText: $colLightGray;
$productListAdvDeleteBtnSurroundColor: $colBlue03;
$productListAdvDeleteTxtColor: $colWhite;
$productListAdvDeleteTxtHoverColor: $colRed;

/* PRODUCT PRICE TABLE */
$productPriceTableBgColor: $colWhite;
$productPriceTableBorderColor: $colGray05;
$productPriceTableTaxColor: $colLightGray;

/* PRODUCT SPOTS */
$productSpotBackground: $colWhite;

/* PRODUCT SPOT HEADING */
$productSpotPriceTagHeaderBackground: $colRed;
$productSpotPriceTagHeaderColor: $colWhite;
$productSpotPriceTagPriceBackground1: $colBlue04;
$productSpotPriceTagPriceBackground2: $colBlue02;
$productSpotPriceTagPriceColor: $colWhite;
$productSpotTitleColor: $colBlack;
$productSpotTitleHoverColor: $colRed;
$productSpotBorderColor: $colGray03;
$productSpotBrandColor: $colLightGray;

/* PRODUCT CAMPAIGNS */
$productCampaignTitle: $colBlack;
$productCampaignTitleHover: $colRed;
$productCampaignPriceText: $colRed;
$productListCampaignHighlightTagBackground: $colRed;
$productListCampaignHighlightTagText: $colWhite;
/* CAMPAIGN HEADER */

/* DISCOUNT BADGES */
$discountBadgeBackground: $colWhite;
$discountBadgeLabelText: $colRed;
$discountBadgeValueText: $colBlack;

/* USER PROFILE MENU */
$userProfileMenuArrowUpColor: $colArrowBg;
$userProfileMenuBorderColor: $colGray01;
$userProfileMenuItemBgColor: $colBlue03;
$userProfileMenuImgBgColor: $colBlue01;
$userProfileMenuTextColor: $colWhite;
$userProfileMenuMouseOverBgColor: $colBlue04;

/* NEWS BOX */
$newsBoxDateTextColor: $colLightGray;
$newsBoxLinkColor: $colBlue02;
$newsBoxDottedSeparatorColor: $colGray03;

/* NEWS LIST */
$newsListBorderColor: $colGray01;

/* QUICKLINKS */
$quicklinksItemBgColor: $colBlue03;
$quicklinksTextColor: $colWhite;
$quicklinksMouseOverBgColor: $colBlue04;
$quicklinksEditLnkColor: $colLightGray;

/* PRODUCT DETAILS */
$productDetailsImgBorderColor: $colGray01;
$productDetailsThumbBorderColor: $colGray01;
$productDetailsThumbActiveBorderColor: $colGray05;
$productDetailsDiscontinuedBgColor: $colLightGray;
$productDetailsDiscontinuedTxtColor: $colRed;
$productDetailsOfferBgColor: $colRed;
$productDetailsOfferTxtColor: $colWhite;
$productDetailsOutletBgColor: $colBlue01;
$productDetailsOutletTxtColor: $colWhite;
$productDetailsFormel1000BgColor: $colBlue01;
$productDetailsFormel1000TxtColor: $colWhite;
$productDetailsSeparatorColor: $colGray03;
$productDetailsLinkColor: $colBlue02;
$productDetailsLightTextColor: $colLightGray;
$productDetailsProdTitleColor: $colBlue02;
$productDetailsPriceBoxBorderColor: $colGray01;
$productDetailsPriceBoxBgColor: $colGray02;
$productDetailsOfferPositiveTxtColor: $colRed;
$productDetailsTechicalDataAltColor: $colGray02;
$productDetailsDownloadAndLinksHeaderBgColor: $colGray02;
$productDetailsDownloadAndLinksHeaderColor: $colBlue02;
$productDetailsDownloadAndLinksNoFurtherFilesOrLinkColor: $colLightGray;

/* TOP SLIDER */
$topSliderBoxBgColor: $colWhite;
$topSliderBoxTitleColor: $colBlue02;
$topSliderBoxTeaserColor: $colBlue02;
$topSliderArrowBgColor: $colRed;

/* TOP CAMPAIGN */
$topCampaignHeaderColor: $colWhite;
$topCampaignBoxBgColor: $colRed;
$topCampaignBoxTextColor: $colWhite;

/* CONTACT SPOT */
$contactSpotTextColor: $colBlue02;
$contactSpotLinkColor: $colBlue02;
$contactSpotSplitColor: $colGray03;

/* BREADCRUMB */
$breadCrumbLinkColor: $colGray05;
$breadCrumbHoverLinkColor: $colLightGray;
$breadCrumbActiveTextColor: $colGray05;
$breadCrumbArrowColor: $colGray05;

/* CHECKOUT */
$checkoutTitleLinkColor: $colBlue02;
$checkoutTeaserTextColor: $colWhite;
$checkoutMainInfoSentTxtColor: $colRed;
$checkoutMainInfoBgColor: $colGray02;
$checkoutMainInfoBorderColor: $colGray01;
$checkoutDottedLineColor: $colGray03;
$checkoutMainLightTextColor: $colLightGray;
$checkoutCertificateLineBgColor: $colWhite;
$checkoutStatusInfoBorderColor: $colBlue03;
$checkoutStatusInfoBgColor: $colBlue07;
$checkoutStatusWarningBorderColor: $colYellow01;
$checkoutStatusWarningBgColor: $colYellow02;
$checkoutStatusErrorBorderColor: $colRed01;
$checkoutStatusErrorBgColor: $colRed02;
$checkoutStatusTextColor: $colBlack;
$checkoutOrderCertificateTextColor: $colGreen;
$checkoutBtnLinkTextColor: $colLightGray;
$checkoutLoneLabelsTextColor: $colBlue02;
$checkoutDeLightedTextColor: $colGray05;
$checkoutAllAdressesColor: brown;
$checkoutErrorTextColor: $colRed;

/* CHECKOUT */
$paymentFormErrorColor: $colRed;
$paymentFormLabelBgColor: $colGray04;
$paymentFormLabelColor: $colGray04;
$paymentDottedLineColor: $colGray03;
$paymentDiscountColor: $colRed;
$paymentExpense: $colLightGray;

/* ADDRESS LIST */
$addressListApprovedGroupBgColor: $colGray01;
$geoAddressErrorColor: $colRed;
$geoAddressSeparatorColor: $colGray05;

/* QUOTE DETAILS */
$quoteDetailsMainInfoBgColor: $colGray02;
$quoteDetailsMainInfoBorderColor: $colGray01;
$quoteDetailsMainSeparatorLineColor: $colLightGray;
$quoteDetailsMainFakeBtnColor: $colDarkGray;
$quoteDetailsMainFakeBtnBorderColor: $colLightGray;
//$quoteDetailsMainSumListRightLineColor		: $colGray03;
$quoteDetailsAllAdressesColor: brown;

/* WAREHOUSE DETAILS */
$warehouseDetailsMainInfoBgColor: $colGray02;
$warehouseDetailsMainInfoBorderColor: $colGray01;
$warehouseDetailsMainSeparatorLineColor: $colLightGray;
$warehouseDetailsAllAdressesColor: brown;
$warehouseDetailsWarningRadioBtnColor: $colRed;
$warehouseDetailsLightTxtColor: $colLightGray;

/* ADD PRODUCT TOOLBAR */
$addProductToolbarExpanderBgColor: $colGray02;
$addProductToolbarExpanderBorderColor: $colGray01;
$addProductToolbarFinishEditBtnBgColor: $colRed;
$addProductToolbarLinkColor: $colBlue02;

/* WORKSHEET DETAILS */
$worksheetImportantColor: red;
$worksheetDetailsHrColor: $colGray03;

/* SENT ORDERS */
$sentOrdersBoxBgColor: $colGray02;
$sentOrdersBoxBorderColor: $colGray01;
$sentOrdersBoxHeaderColor: $colBlue02;
$sentOrdersBoxSubHeaderColor: $colDarkGray;
$sentOrdersBoxTextColor: $colDarkGray;
$sentOrdersBoxLinkColor: $colBlue02;
$sentOrdersPricesTextColor: $colDarkGray;
$sentOrdersPricesTextHighlightedColor: $colBlue01;
$sentOrdersPricesTextDelightedColor: $colGray05;
$sentOrdersPricesTextRedlightedColor: $colOrange;
$sentOrdersPricesSplitColor: $colGray03;

/* TABLE LISTS */
$tableListHeaderBgColor: $colBlue06;
$tableListHeaderBorderColor: $colGray01;
$tableListHeaderTextColor: $colDarkGray;
$tableListHeaderExtraTextColor: $colLightGray;
$tableListItemBgColor: $colGray02;
$tableListItemBorderColor: $colGray01;
$tableListItemTextColor: $colDarkGray;
$tableListItemInvalidBgColor: $colRed01;
$tableListItemMutedTextColor: $colLightGray;
$tableListItemErrorTextColor: $colRed;
$tableListSubItemHeadBgColor: $colGray01;
$tableListSubItemBgColor: $colWhiteGray;
$tableListStepBgColor: $colBlue02;
$tableListStepTextColor: $colWhite;

/* SITEMAP */
$sitemapSectionHeaderColor: $colBlue01;
$sitemapLinkColor: $colGray05;

/* FILTER MENU */
$filterMenuTextColor: $colDarkGray;
$filterMenuLinkTextColor: $colDarkGray;
$filterMenuHeaderTextColor: $colBlue01;
$filterMenuHeaderSplitBorderColor: $colGray03;
$filterTabletMenuTextColor: $colWhite;
$filterTabletMenuLinkTextColor: $colWhite;
$filterTabletMenuHeaderTextColor: $colWhite;
$filterTabletMenuBgColor: $colBlue01;
$filterTabletMenuTextColor: $colWhite;

/* TOOLTIPS */
$tooltipTextColor: $colDarkGray;
$tooltipBorderColor: $colGray03;
$tooltipArrowUpColor: $colArrowBg;
$tooltipErrorTextColor: $colRed;
$tooltipWarningTextColor: $colOrange;
$tooltipWhiteBgColor: $colWhite;
$tooltipWhiteBorderColor: $colGray05;
$tooltipWhiteBulletColor: $colBlue03;
$tooltipWhiteSeparatorColor: $colGray01;

/* DATEPICKER */
$datePickerBorderColor: $colGray01;
$datePickerBgColor: $colWhite;
$datePickerRowActiveBgColor: $colBlue01;
$datePickerRowActiveTextColor: $colWhite;
$datePickerRowHoverBgColor: $colGray02;
$datePickerRowNowTextColor: $colBlue01;
$datePickerRowDisabledTextColor: $colGray03;

/* ADDTO */
$AddToErrorMessage: $colRed;

/* PUBLICATIONS */
$publicationsSeparatorColor: $colBlue01;
$publicationsHeaderTxtColor: $colBlue02;

/* PAGINATION */
$paginationTextColor: $colDarkGray;
$paginationBgColor: $colWhite;
$paginationTextHoverColor: $colDarkGray;
$paginationBgHoverColor: $colGray02;
$paginationActiveTextColor: $colWhite;
$paginationActiveBgColor: $colBlue01;
$paginationBorderColor: $colGray01;
$paginationDisabledTextColor: $colGray01;
$paginationDisabledBgColor: $colWhite;

/* ACCORDION */
$panelHeadingBorderColor: $colGray05;
$panelHeadingTitleToggleText: $colGray05;
$panelHeadingLinkColor: $colBlue02;
$panelItemsBackGroundColor: $colGray02;
$panelItemsBorderColor: $colGray01;

/* FAVORITES ACCORDION */
$favoritesAccordionDimmedText: $colGray05;
$favoritesListEditLnkColor: $colWhite;
$favoritesListToolbarBgColor: $colBlue06;
$favoritesListTitleListCount: $colGray05;

/* USER PROFILE ADMINISTRATION */
$userProfileDetailsFadedInfo: $colGray05;
$userProfileDetailsAccordionHeaderBgColor: $colBlue01;
$userProfileDetailsAccordionHeaderTxtColor: $colWhite;
$userProfileDetailsAccordionHeadlineColor: $colBlue02;
$userProfileDetailsAccountTblBaseColor: $colWhite;
$userProfileDetailsAccountTblSepColor: $colLightGray;
$userProfileDetailsRequiredColor: $colRed;

/* KLS SETUP */
$klsSetupAccordionHeader0BgColor: $colBlue01;
$klsSetupAccordionHeader0TxtColor: $colWhite;
$klsSetupAccordionHeader10BgColor: $colGray02;
$klsSetupAccordionHeader10TxtColor: $colBlue01;
$klsSetupAccordionHeader20BgColor: $colGray02;
$klsSetupAccordionHeader20TxtColor: $colGray05;

/* TOASTR */
$toastrBgColor: $colBlue01;
$toastrBorderColor: $colBlue02;
$toastrTextColor: $colWhite;
$toastrSplitLine: $colBlue01;
$toastrProgressBarColor: $colWhite;
$toastrButtonBgColor: $colBlue01;
$toastrInfoBgColor: $colGreen;
$toastrSuccesBgColor: $colBlack;
$toastrWarningBgColor: $colYellow;
$toastrErrorBgColor: $colRed;

/* SEARCH */
$searchResultSectionHeaderColor: $colBlue02;
$searchResultSectionHeaderBackground: $colGray02;
$searchResultBorderColor: $colGray01;

/* DEALER */
$dealerBgColor: $colGray02;
$dealerBorderColor: $colGray01;

/* PAGEMULTISPOT */
$pageMultiSpotBgColor: $colGray02;
$pageMultiSpotBorderColor: $colGray01;
$pageMultiSpotQuestionTextColor: $colWhite;
$pageMultiSpotQuestionLinkColor: $colBlue02;
$pageMultiSpotQuestionBgColor: $colGray05;
$pageMultiSpotOverlayBgColor: $colBlue01;

/* IMAGE MAP */
$imageMapIndicationTextColor: $colGreen;
$imageMapOverlayColor: $colDarkGray;

/* LANGUAGECHOOSER */
$languageChooserBgColor: $colGray01;
$languageChooserChosenBgColor: $colGray02;
$languageChooserBorderColor: $colGray03;

/* BROACHED UNITS */
$broachedUnitsRowBorderColor: $colLightGray;
$broachedUnitsAmountColumnColor: $colGreen;

/* PRICE DISCOUNTS */
$priceDiscountFadedPrice: $colLightGray;
$priceDiscountDiscountAmount: $colRed;
$priceDiscountCustomerPrice: $colBlack;

/* OWN PRODUCTSLIST */
$ownProductsListAllAdressesColor: brown;
$ownProductsListMainInfoBgColor: $colGray02;
$ownProductsListMainInfoBorderColor: $colGray01;

$ownProductsListMainSeparatorLineColor: $colLightGray;
$ownProductsListMainFakeBtnColor: $colDarkGray;
$ownProductsListMainFakeBtnBorderColor: $colLightGray;
