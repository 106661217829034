#footerNewsletter h3 {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 400;
    text-transform: none;
    color: white;
    background-color: transparent;
}

#footerNewsletter p {
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: white;
}

#footerSignupForm .footerSignupContainer {
    display: block;
    margin: 15px 0;

    input {
        height: 50px;
        border-radius: 6px;
        box-shadow: none;
    }
}

#footerSignupForm .footerSignupContainer + .footerSignupContainer input {
    margin-top: 0;
}

#footerSignupBtn {
    min-width: 130px;
    height: 50px;
    line-height: 50px;
    margin: 0;
    padding: 0 15px;
    background-color: #c80000;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    cursor: pointer;
}
