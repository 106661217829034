@import "./quote.scss";

// Font properties
$fontColorHeaderDate: $newBlack;

$fontColorHeaderTeaser: $colBlack;

$fontColorHeaderTitle: $colBlack;

$borderColorQuote: $colorPowderBlue;

.newsContent {
    margin: 10px 15px;
    @include breakpoint(phone) {
        margin-top: 36px;
    }
}

.newsContent > .newsBody {
    font-size: 16px;
    line-height: 26px;

    // #region Related elements in SCSS/lemurte.scss (rich text editor)
    .button {
        @extend .bloodOrangeButton;
        font-weight: $mediumFontWeight;
        line-height: 1.2;
        padding: 15px 40px;
        height: auto;
    }
    .quote {
        @extend .quote;
        li {
            list-style-position: inside;
        }
        ul,
        ol,
        p {
            padding: 0;
            margin: 0;
        }
    }
    li .quote {
        border: none;
        padding: 5px 0;
        &::before {
            content: "";
            position: absolute;
            width: 10px;
            height: 36px;
            background: $powderBlue;
            transform: translate(-40px, -3px);
        }
    }
    // #endregion

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 500;
        margin: 7px 0;
    }
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 18.72px;
    }
    h4 {
        font-size: 16px;
    }
    h5 {
        font-size: 13.28px;
    }
    h6 {
        font-size: 10.72px;
    }
    img {
        height: auto;
        width: 100%;
    }
}

.newsContent > header {
    margin-bottom: 25px;
    height: fit-content;
    overflow: hidden;
    background: $colGray02;
    .newsHeaderImage > img {
        width: 100%;
    }

    .newsHeaderText {
        padding: 30px 80px 50px 30px;
        @include breakpoint(phone) {
            padding: 22px 60px 38px 15px;
        }
        > .newsDate {
            color: $fontColorHeaderDate;
            font-size: 13px;
            font-weight: $boldFontWeight;
            line-height: 1.69;
        }

        > .newsTitle {
            overflow: hidden;
            font-weight: $boldFontWeight;
            color: $fontColorHeaderTitle;
            margin: 15px 0 0;
            line-height: 43px;
            font-size: 36px;
            @include breakpoint(phone) {
                margin: 10px 0 0;
                line-height: 38px;
                font-size: 30px;
            }
        }

        > .newsTeaser {
            overflow: hidden;
            font-weight: 300;
            color: $fontColorHeaderTeaser;
            margin: 20px 0 0;
            line-height: 32px;
            font-size: 26px;
            @include breakpoint(phone) {
                margin: 18px 0 0;
                line-height: 24px;
                font-size: 16px;
            }
        }
    }
}
