@import "~Utilities/variables";
@import "~Utilities/mixins";

@include breakpoint(phone) {
    .footerSection__language-chooser {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        > div {
            margin-left: auto;
        }
    }
}

.language-chooser {
    position: relative;
    margin: 5px 0 0;
    font-size: 0;
    color: white;

    @media (min-width: 768px) {
        margin: 15px 0 0;
    }

    &__label {
        position: relative;
        color: inherit;
        opacity: 0.33;
        transition: opacity 0.25s ease;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        display: inline-block;
        width: 20px;
        margin: 0;
        text-align: center;
        text-transform: uppercase;

        &:before {
            display: none;
        }

        &:last-of-type {
            margin-left: 42px;
        }

        &--checked {
            opacity: 1;
        }
    }

    &__input {
        box-sizing: border-box;
        cursor: pointer;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        width: 100%;

        &:checked {
            cursor: default;
        }
    }

    &__toggle-outside {
        background: transparent;
        border-radius: 7px;
        border: 2px solid;
        height: 14px;
        left: 30px;
        overflow: hidden;
        cursor: pointer;
        padding: 0;
        position: absolute;
        top: 0;
        transition: 0.25s ease all;
        width: 20px;
        &:hover {
            background-color: transparent;
        }
    }

    &__toggle-inside {
        position: absolute;
        left: 2px;
        top: 2px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: currentColor;
        transition: 0.25s ease all;
    }
}
