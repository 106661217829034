h1,
.h1 {
    font-size: 34px;
    font-weight: 700;
    line-height: 1.18;
}

h2,
.h2 {
    font-size: 36px;
    font-weight: 300;
    line-height: 1.19;
}

h3,
.h3 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
}
