@import "~Utilities/variables.scss";
@import "~Utilities/colorScheme.scss";

$block: ".menu-dropdown";

$font__color: $colorBrownishGray;
$font__color--header: $colBlack;
$color__link-hover: $colorBloodOrange;

#{$block} {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    max-width: $fullWidth;
    margin: 0 auto;
    overflow: hidden;
    padding: 24px 0 0 0;

    &__column {
        flex: 0 0 25%;
        min-width: 25%;
        padding: 0 15px;
        margin: 20px 0 56px 0;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__header {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 25px 0;

        > a:link,
        a:active,
        a:visited {
            color: $font__color--header;

            &:hover {
                color: $color__link-hover;
                text-decoration: underline;
            }
        }

        &--active {
            > a:link,
            a:active,
            a:visited {
                color: $color__link-hover;
                text-decoration: underline;
            }
        }
    }

    &__link {
        margin: 0 0 15px 0;

        > a:link,
        a:active,
        a:visited {
            line-height: 20px;
            transition: color 250ms ease-in-out;
            color: $font__color;

            &:hover,
            &:focus {
                color: $color__link-hover;
                text-decoration: underline;
            }
        }

        &--active {
            > a:link,
            a:active,
            a:visited {
                color: $color__link-hover;
                text-decoration: underline;
            }
        }
    }
}
