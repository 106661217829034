$block: ".off-canvas";

$background__color--hidden-content: $colorOffWhite;

#{$block} {
    transform: translate3d(0, 0, 0);
    transition: transform 300ms cubic-bezier(0.9, 0.03, 0.69, 0.22);

    @media (min-width: 768px) {
        transform: none;
    }

    .show-off-canvas & {
        transform: translate3d(-100%, 0, 0);
    }

    .show-off-canvas.show-off-canvas--in-from-left & {
        transform: translate3d(260px, 0, 0);

        @media (min-width: 768px) {
            transform: translate3d(400px, 0, 0);
        }
    }

    &__hidden-content {
        position: absolute;
        top: 0;
        right: -100%;
        width: 100%;
        background-color: $background__color--hidden-content;
        height: 100%;
    }
}
