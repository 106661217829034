:root {
    --successtextColor: #3c763d;
    --successBorderColor: #dff0d8;
    --successBackgroundColor: #dff0d8;
    --errorTextColor: #a94442;
    --errorBorderColor: #ebccd1;
    --errorBackgroundColor: #f2dede;
    --infoTextColor: #31708f;
    --infoBorderColor: #bce8f1;
    --infoBackgroundColor: #d9edf7;
}

.notification-wrapper {
    /* position: absolute; */
    border-radius: 2px;
    margin: 0.5rem;
    padding: 0.5rem;
}

.notification-error {
    color: var(--errorTextColor);
    background-color: var(--errorBackgroundColor);
    border-color: var(--errorBorderColor);
}
.notification-info {
    color: var(--infoTextColor);
    background-color: var(--infoBackgroundColor);
    border-color: var(--infoBorderColor);
}
.notification-success {
    color: var(--successTextColor);
    background-color: var(--successBackgroundColor);
    border-color: var(--successBorderColor);
}
