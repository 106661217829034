.bloodOrangeButton {
    background-color: $accentColorNew;
    text-transform: none;
    font-size: 16px;
    font-weight: 400;
    border: 2px solid $accentColorNew;
    @extend .roundedCorners;

    &:hover {
        background-color: $whiteout;
        color: $accentColorNew;
    }
}

.roundedCorners {
    border-radius: 6px;
}

.sectionContent {
    @include sectionContent();
}
