$block: ".accordion";

$font__color: $color__black;

#{$block} {
    h3 {
        font-size: 16px;
        color: $font__color;
        font-weight: 500;
        text-transform: none;
        border: none;
        margin: 0 0 10px 0;
        padding-bottom: 0;
    }

    &__expand {
        opacity: 0.3;
        font-size: 16px;
        text-decoration: none !important;

        &:before {
            content: "";
            display: inline-block;
            width: 17px;
            height: 17px;
            margin: 0 11px 0 30px;
            transform: translate3d(0, 4px, 0);
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij4KICAgIDxwYXRoIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTkgMHY4aDh2MUg5djhIOFY5SDBWOGg4VjBoMXoiLz4KPC9zdmc+Cg==")
                no-repeat;
        }
    }

    &.accOpen {
        #{$block}__content {
            max-height: 300px;
        }
    }

    &.accClosed {
        > h3 {
            margin: 0 0 10px 0;
        }

        #{$block}__content {
            max-height: 0;
        }
    }

    &.expanded {
        #{$block}__content {
            max-height: none;
        }

        &.accClosed {
            #{$block}__content {
                max-height: 0;
            }
        }
    }

    &__content {
        display: block !important;
        max-height: 400px;
        transition: max-height 325ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }

    &__collapse {
        opacity: 0.3;
        font-size: 16px;
        text-decoration: none;

        &:before {
            content: "";
            display: inline-block;
            width: 17px;
            height: 17px;
            margin-right: 11px;
            margin: 0 15px 0 30px;
            transform: translate3d(0, 11px, 0);
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxIiB2aWV3Qm94PSIwIDAgMTcgMSI+CiAgICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBoMTd2MUgweiIvPgo8L3N2Zz4K")
                no-repeat;
        }
    }
}
