/************************************************/
/*			GRID								*/
/************************************************/

@mixin gridItem($value: 1, $addGutter: true) {
    @if $addGutter == false {
        width: ($columnWidth * $value) + ($gutter * ($value - 1));
    } @else {
        width: ($columnWidth * $value) + ($gutter * $value);
    }
}

@mixin boxItem($value: 1) {
    width: percentage(1 / $tablecolumns * $value);

    @include breakpoint(desktop) {
        width: ($columnWidth * $value) + ($gutter * $value);
    }
}

/************************************************/
/*			BREAKPOINTS							*/
/************************************************/

@mixin breakpoint($point: desktop) {
    @if $point == desktop {
        @media (min-width: $fullWidth) {
            @content;
        } // + 24 extra for scrollMenu etc.
    } @else if $point == tablet {
        @media (max-width: $fullWidth - 1) and (min-width: $tabletMinWidth) {
            @content;
        }
    } @else if $point == phone {
        @media (max-width: $tabletMinWidth - 1) {
            @content;
        }
    } @else if $point == tabletdesktop {
        @media (min-width: $tabletMinWidth) {
            @content;
        }
    } @else if $point == tabletphone {
        @media (max-width: $fullWidth - 1) {
            @content;
        }
    }
}

/************************************************/
/*			CUSTOM								*/
/************************************************/
@mixin sectionContent($background-color: $colWhite) {
    background-color: $background-color;
    margin: auto;
    max-width: 1300px;
    display: grid;
    padding: 20px;
}
