/************************************************/
/*			_layouts.scss						*/
/*			Compiles to common.css				*/
/*			Original author: PSJ				*/
/*			Contributers:						*/
/************************************************/
/// <reference path="common.scss" />

html,
body,
select,
input,
textarea {
    font-family: "Inter", Helvetica, Sans-Serif;
    font-weight: 400;
}

.svg-icon,
.img-icon {
    width: 50px;
    height: 50px;

    > img {
        max-width: 100%;
        height: auto;
    }
}
