@import "~Utilities/colorScheme.scss";

$block: ".multi-level-menu-link";
$color__hover: $colorBloodOrange;
$color__font: $colorBrownishGray;
$color__font--active: $colBlack;

#{$block} {
    a:link,
    a:active,
    a:visited {
        color: $color__font;
    }

    a {
        text-decoration: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    &__arrow {
        transform: rotateX(180deg);
        background-color: transparent;
        color: inherit;
        font-size: 24px;
        cursor: pointer;
        height: auto;
        line-height: 1;
        &:focus,
        &:hover {
            background-color: transparent;
        }

        .ficon {
            display: inline-block;
            transition: transform 275ms cubic-bezier(0.4, 0, 0.2, 1);
        }

        &:hover,
        &:focus {
            svg {
                path {
                    fill: $color__hover !important;
                }
            }
        }
    }

    &--open {
        > #{$block}__container {
            a:link,
            a:active,
            a:visited {
                font-weight: 700;
                color: $color__font--active;
            }

            .ficon {
                transform: rotateX(180deg);
            }
        }
    }

    &--active {
        > #{$block}__container {
            > a:link,
            a:active,
            a:visited {
                color: $color__hover;
            }
        }
    }
}

@keyframes active {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
