.i2q-loading-spinner-wrapper {
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 240, 240, 0.5);
}

.i2q-loading-spinner-wrapper.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    min-width: 100;
    min-height: 100;
}

.i2q-loading-text {
    text-align: center;
    font-size: 2rem;
}
