.quote {
    display: block;
    border-left: 10px solid $powderBlue;
    color: $colBlack;
    text-align: left;
    padding: 5px 0 5px 40px;
    width: auto;
    font-style: normal;
    line-height: 26px;
    /* Related: .quote in "SCSS/lemurte.scss" */
}
