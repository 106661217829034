$block: ".basket-menu";

$font__color: $colBlack;
$font__color--type: $colorBrownishGray;
$font__color--checkout: $colWhite;
$font__color--hover: $colorBloodOrange;
$background__checkout: $colorBloodOrange;
$background__menu: $colWhite;
$border__color: $colorPowderBlue;

#{$block} {
    display: none;
    background-color: $background__menu;
    width: 100vw;
    z-index: 1;
    padding: 30px 20px;
    color: $font__color;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -207%;
    bottom: 0;
    transform: translate3d(0, 100%, 0);

    @media (min-width: 580px) {
        width: 440px;
        right: 0;
    }

    &__content {
    }

    &__heading {
        font-size: 22px;
        font-weight: 700;
        margin: 0 0 20px 0;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border-top: solid 2px $border__color;
            border-bottom: solid 2px $border__color;

            + li {
                border-top: none;
            }
        }
    }

    &__item {
        display: flex;
        align-items: flex-start;
        padding: 15px 0;
    }

    &__image {
        > img {
            max-width: 60px;
        }
    }

    &__text {
        margin: 0 45px 0 15px;
    }

    &__name {
        margin: 0;
        font-weight: 500;

        > a:visited,
        a:link,
        a:active {
            color: $color__black;

            &:hover {
                color: $font__color--hover;
            }
        }
    }

    &__type {
        margin: 20px 0 0 0;
        color: $font__color--type;
    }

    &__amount {
        margin-left: auto;
        min-width: 50px;
        font-weight: 500;
        text-transform: lowercase;

        &:after {
            content: ".";
        }
    }

    &__delete {
        display: block;
        margin: 40px 0 30px 0;
        text-align: center;
        font-weight: 500;
        cursor: pointer;

        i {
            display: inline-block;
            margin: 0 7px 0 0;
            transform: translate3d(0, 2px, 0);
        }

        &:hover {
            color: $font__color--hover;

            path {
                fill: $font__color--hover !important;
            }
        }
    }

    &__checkout {
        display: block;
        text-align: center;
        background-color: $background__checkout;
        color: $font__color--checkout !important;
        border-radius: 6px;
        padding: 20px 30px;
        border: solid 2px $background__checkout;
        transition: background-color 225ms
            cubic-bezier(0.895, 0.03, 0.685, 0.22),
            color 225ms cubic-bezier(0.895, 0.03, 0.685, 0.22);

        &:hover {
            color: $background__checkout !important;
            background-color: $colWhite;
        }
    }
}
