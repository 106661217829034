@import "~Utilities/colorScheme";
@import "~Utilities/variables";

$bgColorCardExpanded: $powderBlue;
$bgColorCardExpandedFocus: $colBlue04;
$bgColorCardMinimalist: $offWhite;
$bgColorCard: $colGray02;
$bgColorCardFocus: $colGray01;

$fontSizeTeaser: 16px;
$fontColorTeaser: $colBlack;

$fontColorDate: $newBlack;
$fontColorHeading: $colBlack;

$fontSizeHeading: 24px;
$fontSizeHeadingExpanded: 36px;
$fontSizeHeadingExpandedMobile: 30px;

$fontSizeDate: 13px;

@mixin expandedCardStyle() {
    width: 100%;
    background: $bgColorCardExpanded;
    @include breakpoint(tabletdesktop) {
        width: 65%;
    }
    .newsContent {
        .newsHeading {
            font-size: $fontSizeHeadingExpanded;
        }
    }
    .newsImage {
        width: auto;
    }
    &:hover,
    &:focus {
        background-color: $bgColorCardExpandedFocus;
    }
}

@mixin minimalistCardStyle() {
    background: $bgColorCardMinimalist;
    .newsImage {
        display: none;
    }
    &:hover,
    &:focus {
        background-color: $bgColorCardFocus;
    }
}

.newsList--reskinned {
    /* adding the BEM --reskinned here to avoid collision with common.scss styles*/
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 10px;
    .newsItem {
        background: $bgColorCard;
        border: none;
        width: 100%;
        margin: 1%;

        @include breakpoint(tabletdesktop) {
            width: 32%;
            margin: 0.6%;
        }
        cursor: pointer;
        transition: background-color 0.1s;
        &:hover,
        &:focus {
            background-color: $bgColorCardFocus;
        }
        &:nth-child(1) {
            @include expandedCardStyle();
        }
        &:nth-child(12n + 2),
        &:nth-child(12n + 6) {
            @include minimalistCardStyle();
        }
        a {
            text-decoration: none;
        }
        .newsItemWrapper {
            display: flex;
            flex-direction: column;
            padding: 0;
        }
    }

    .newsImage {
        overflow: hidden;
        height: auto;
        > img {
            min-height: 70px;
            width: 100%;
            @include breakpoint(tabletphone) {
                height: auto;
            }
        }
    }

    .newsContent {
        margin: 25px 15% 15px 30px;
        overflow: hidden;
        height: auto;
        padding: 0;
        .newsDate {
            font-size: $fontSizeDate;
            font-weight: bold;
            line-height: 1.69;
            color: $fontColorDate;
        }
        .newsHeading {
            text-transform: none;
            font-size: $fontSizeHeading;
            font-weight: bold;
            line-height: 1.26;
            color: $fontColorHeading;
            margin: 10px 0;
        }
        .newsTeaser {
            font-size: $fontSizeTeaser;
            line-height: 1.5;
            color: $fontColorTeaser;
        }
    }
}
