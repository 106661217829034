$block: ".form";

$font__color: $color__black;
$font__color--alt: $colorBrownishGray;
$border__color--checkbox: $colorBrownishGray;
$background__color--select: $colorPowderBlue;

#{$block} {
    label {
        color: $font__color;
        font-size: 16px;
        display: inline-block;
    }

    &__group {
        padding: 0;
        margin-bottom: 10px;
    }
    &__group--longtail {
        display: inline-block;
        margin-right: 10px;
    }

    &__checkbox {
        display: none;

        + label {
            position: relative;
            padding: 0 0 0 28px;
            line-height: 1;

            &:after {
                content: "";
                position: absolute;
                left: 3px;
                top: 4px;
                z-index: 1;
                width: 10px;
                height: 9px;
                opacity: 0;
                transition: opacity 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
                background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTAgOSI+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgeD0iMSIgeT0iMSIgc3Ryb2tlPSIjNjA2MDYwIiBzdHJva2Utd2lkdGg9IjIiIHJ4PSI0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNCAtNSkiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjNjA2MDYwIiBkPSJNMS44IDMuNDI1bDIuMjk3IDEuOTI4TDcuOTU0Ljc1N2wxLjUzMiAxLjI4Nkw1LjYzIDYuNjM4di4wMDFMNC4zNDUgOC4xNzEuNTE0IDQuOTU3bDEuMjg1LTEuNTMyeiIvPgogICAgPC9nPgo8L3N2Zz4K")
                    no-repeat;
            }

            &:before {
                content: "";
                margin: 0;
                position: absolute;
                left: 0;
                display: inline-block;
                border: solid 2px $border__color--checkbox;
                border-radius: 4px;
                width: 16px;
                height: 16px;
                background: none;
            }
        }

        &:checked {
            + label {
                font-weight: 400;

                &:before {
                    margin: 0;
                    background: none;
                    width: 16px;
                    height: 16px;
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__checkbox--longtail {
        vertical-align: middle;
    }

    &__label--longtail {
        vertical-align: middle;
    }

    &__radio {
    }

    &__text {
        border: solid 1px rgba(70, 96, 108, 0.18);
        box-shadow: none;
        color: $font__color--alt;

        &--w40 {
            width: 40px;
            height: 42px;
            margin: 0;
            padding: 0;
            text-align: center;
        }
    }

    &__select {
        position: relative;
        border: solid 1px rgba(70, 96, 108, 0.18);
        background-color: $colWhite;

        @media (min-width: 1025px) {
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                width: 40px;
                height: 40px;
                background-color: $background__color--select;
                cursor: pointer;
                pointer-events: none;
            }

            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                top: calc(50% + 2px);
                right: 12px;
                transform: translate3d(0, -50%, 0) rotateX(180deg);
                width: 16px;
                height: 16px;
                z-index: 1;
                cursor: pointer;
                pointer-events: none;
                background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlNvbGlkX194NDBfMngiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCINCgkgeT0iMHB4IiB2aWV3Qm94PSIyNzEgMTYwLjggNDE4LjEgMjM4LjMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMjcxIDE2MC44IDQxOC4xIDIzOC4zIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiM1QTZFODIiIGQ9Ik01MDAuNywxNjkuNGwxNzkuOCwxNzkuOGMxMS40LDExLjQsMTEuNCwzMCwwLDQxLjRjLTExLjQsMTEuNC0zMCwxMS40LTQxLjQsMEw0ODAsMjMxLjVMMzIwLjksMzkwLjYNCgljLTExLjQsMTEuNC0zMCwxMS40LTQxLjQsMGMtMTEuNC0xMS40LTExLjQtMzAsMC00MS40bDE3OS44LTE3OS44YzUuNy01LjcsMTMuMi04LjYsMjAuNy04LjZDNDg3LjUsMTYwLjgsNDk1LDE2My43LDUwMC43LDE2OS40eiINCgkvPg0KPC9zdmc+DQo=")
                    center center no-repeat;
            }
        }

        &--border-none {
            border: none;
        }

        > select {
            border: none;
            color: $font__color--alt;
            padding-left: 10px;
            padding-right: 50px;
            width: 100%;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;

            &::-ms-expand {
                display: none;
            }
        }

        &--width-full {
            > select {
                width: 100%;
            }
        }
    }
}

/* flag for IE - no custom select styles should be applied */
@supports (display: grid) {
    .custom-select {
        position: relative;
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 40px;
            height: 40px;
            z-index: 1;
            cursor: pointer;
            pointer-events: none;
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTQgOSI+CiAgICA8cGF0aCBmaWxsPSIjNjA2MDYwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDYuMjQzTDEuMzQzLjU4Ni0uMDcgMiA3IDkuMDcxIDE0LjA3MSAyIDEyLjY1Ny41ODZ6Ii8+Cjwvc3ZnPgo=")
                center no-repeat;
            background-color: $powderBlue;
        }
        select {
            height: 40px;
            border-color: $powderBlue;
            padding-right: 40px;
        }
    }
}
