/* flexbox */

.flex {
    display: flex !important;

    &--align-center {
        align-items: center !important;
    }

    &--justify-center {
        justify-content: center;
    }
}

.margin {
    &--bottom10 {
        margin-bottom: 10px;
    }

    &--top10 {
        margin-top: 10px;
    }

    &--bottom20 {
        margin-bottom: 20px;
    }

    &--top20 {
        margin-top: 20px;
    }
}
