$fieldGapSize: 10px;

#addProductToolbarWrapper {
    background: $offWhite;
    color: $newBlack;
    padding: 10px 17px 15px;
    margin: 10px;
}

#addProductToolbarWrapper > .toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    flex-flow: row wrap;
    .column {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $pinkishGray;
        border-right: 1px solid $pinkishGray;
        padding: 10px;
        margin: 5px 0;
        > *:not(:first-child) {
            margin-left: $fieldGapSize;
            overflow: visible;
            align-self: flex-end;
        }
        &:first-child,
        &:nth-child(4n) {
            justify-content: flex-start;
            border: none;
            padding: 10px 10px 10px 0;
        }
        &:nth-child(3n) {
            flex-direction: column;
            align-items: flex-end;
            text-align: end;
            border: none;
            padding: 10px 0px 10px 10px;
            > * {
                margin-left: 0px;
            }
        }
        &.column__search {
            flex: 3;
            > .addProductLongField {
                flex: 4;
                margin-left: 0px;
            }
            > .searchBoxDropdownWrapper {
                position: absolute;
                max-width: 500px;
                max-height: 500px;
                align-self: baseline;
                transform: translate(-195px, 15px);
                @supports (display: grid) {
                    transform: translate(-10px, 37px);
                }
            }
            &.column__search--allow-unknown-products {
                > .searchBoxDropdownWrapper {
                    transform: translate(-195px, 35px);
                    @supports (display: grid) {
                        transform: translate(-10px, 74px);
                    }
                }
                .custom-select::before {
                    bottom: auto;
                }
            }
        }
        &.column__add-own-products {
            padding-right: 20px;
            > .add-own-products__field-group {
                display: flex;
                flex-flow: row wrap;
                flex: 5;
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-gap: 10px;
                }
                > button {
                    flex: 1;
                }
                > .add-product__field {
                    width: 110px;
                    margin-right: 10px;
                    justify-content: flex-end;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &.column__file-upload {
            flex-direction: column;
            align-items: baseline;
            min-width: 60%;
            > * {
                margin: 10px 0;
                width: auto;
            }
            button {
                height: auto;
                padding: 10px 0;
                margin: 0;
            }
            .fileUploadResultCnt {
                position: relative;
            }
            .fileUploadResultScroll {
                overflow: auto;
                align-content: center;
                max-height: 200px;
                overflow-y: auto;
                position: relative;
            }
            .fileUploadResultTitle {
                font-weight: bold;
                padding-bottom: 5px;
            }
            .consoleStatusMessage {
                div {
                    min-height: 10px;
                }
            }
            .fileUploadResultScrollGradient {
                position: absolute;
                bottom: 0;
                left: 0;
                width: calc(100% - 20px);
                margin: 0;
                padding: 0;
                text-align: center;
            }
        }
    }
    button {
        background: transparent;
        display: flex;
        margin: 0px $fieldGapSize 0px;
        text-transform: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        color: $colorBloodOrange;
        padding: 0;
        &#btnSearchInProducts {
            align-items: center;
            .icon {
                transition: transform 0.25s ease;
                margin-left: $fieldGapSize;
            }
            &:hover,
            &:focus {
                .icon {
                    transform: translateX(3px);
                }
            }
        }
    }
    select,
    input {
        border: 1px solid $powderBlue;
        color: $colorBrownishGray;
        box-shadow: none;
    }
    input,
    select {
        width: 100%;
    }
    label {
        margin: 10px 0;
        display: inline-block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.add-product__field {
    display: flex;
    flex-direction: column;
}

.add-product__field--short {
    @extend .add-product__field;
    min-width: 83px;
    overflow: hidden;
}

#expanderBar {
    text-align: center;
    button.btnExpand {
        background: transparent;
        text-transform: capitalize;
        color: $newBlack;
        .btnContent {
            display: flex;
            align-items: center;
            > span {
                display: flex;
            }
            > p {
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                margin: 0 0 0 8px;
                &:hover,
                &:focus {
                    color: $colorBloodOrange;
                }
            }
        }
    }

    .expandText {
        color: $newBlack;
    }
}
