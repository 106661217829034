$block: ".product-item";

$background__color: #f4f4f4;
$background__btn: $colorBloodOrange;
$background__info-icon: $colorBrownishGray;
$font__color: $color__black;
$font__color--hover: $colorBloodOrange;

#{$block} {
    display: flex;
    background-color: $background__color;
    padding: 15px;
    margin-bottom: 20px;
    color: $font__color;

    &__bulkactions {
        display: none;
    }
    @media (min-width: 768px) {
        &__bulkactions {
            display: flex;
            height: 23px;
            align-items: center;

            &-add,
            &-favorite,
            &-mark {
                padding-left: 15px;
            }

            &-mark {
                margin-top: -8px;
                margin-right: -15px;
            }

            &-favorite {
                svg {
                    path {
                        stroke: #606060;
                    }
                }
                &.productItemFavoriteChosen1 {
                    svg {
                        path {
                            fill: #606060;
                        }
                    }
                }
            }
        }
    }

    &__image {
        position: relative;
        margin-right: 20px;
        height: 193px;
        width: 150px;
        background-color: $colWhite;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 150px;

        img {
            max-height: 140px;
            max-width: 140px;
        }

        @media (min-width: 480px) {
            height: 150px;
        }

        @media (min-width: 992px) {
            height: 200px;
            width: 200px;
            flex: 1 0 200px;

            img {
                max-height: 185px;
                max-width: 185px;
            }
        }
    }

    &__badge {
        position: absolute;
        top: 0;
        right: 0;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 69px 69px 0;
            border-color: transparent #990000 transparent transparent;
        }

        > span {
            position: absolute;
            top: 15px;
            right: 5px;
            display: inline-block;
            max-width: 45px;
            color: $colWhite;
            font-size: 10px;
            z-index: 1;
            transform: rotate(45deg);
            text-align: center;
        }

        &.formel1000 {
            &:before {
                border-color: transparent #324c59 transparent transparent;
            }

            > span {
                max-width: 45px;
                top: 12px;
                right: 4px;
            }
        }
    }

    &__benefice {
        position: absolute;
        bottom: 10px;
        left: 10px;
        background-color: #324c59;
        color: white;
        font-size: 10px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @media (min-width: 480px) {
        }
    }

    &__row {
        position: relative;
        display: flex;
        width: 100%;

        + #{$block}__row {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;

            @media (min-width: 480px) {
                flex-direction: row;
            }
        }
    }

    &__heading {
        width: 100%;
        margin-bottom: 21px;

        > a:link,
        a:active,
        a:visited {
            display: block;
            margin: 0 0 8px 0;
            font-size: 14px;
            font-weight: 700;
            text-decoration: none;
            transition: color 225ms cubic-bezier(0.075, 0.82, 0.165, 1);
            color: inherit;

            &:hover {
                color: $font__color--hover;
            }

            @media (min-width: 992px) {
                font-size: 22px;
            }
        }

        p {
            margin: 0;
            font-size: 12px;

            @media (min-width: 992px) {
                font-size: 16px;
            }
        }
    }

    &__combined-name {
        margin: 0 0 20px 0;
        font-size: 16px;
    }

    &__numbers {
        display: flex;
        font-size: 12px;
        margin-bottom: 15px;

        @media (min-width: 992px) {
            font-size: 14px;
        }
    }

    &__numbers-text {
        @media (min-width: 768px) {
            margin: 0 30px 0 0;
        }
        color: $colorBrownishGray;
    }

    &__numbers-values {
        font-weight: 700;
    }

    &__stock {
        margin: auto 0 0 0;
        display: none;
        flex-direction: column;
        color: $colorBrownishGray;

        .inStock {
            color: #4cb66c !important;
        }

        > .productItemStockCentral {
            margin: 0 0 5px 0;
        }

        > .productItemStockCentral,
        > .productItemStockClosest {
            width: auto !important;
            float: none !important;
            padding: 0 !important;
        }

        @media (min-width: 992px) {
            display: flex;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__buy-col {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 0;

        @media (min-width: 480px) {
            display: flex;
            margin: 0 0 0 20px;
        }
    }

    &__price {
        .productItemPricesTableWrap {
            position: absolute;
            right: -15px;
            top: calc(100% + 8px);
            padding: 0;
            z-index: 2;
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
            text-align: left;
            font-weight: 400;

            &:before {
                position: absolute;
                right: 0;
                top: 0;
                width: 53px;
                height: 4px;
                background-color: $accentColorNew;
                content: "";
            }

            [type="checkbox"]:checked + label,
            [type="radio"]:checked + label {
                font-weight: 500;
            }

            .form__checkbox + label:before {
                top: 0;
            }
            .form__checkbox + label:after {
                top: 5px;
            }

            .formBoxList .formBoxItem {
                padding: 0;
            }

            .productItemPricesTable {
                border: 0;

                float: none;
                padding: 20px;
                max-width: 402px;

                .productItemPricesTableRow
                .productItemPricesTablePricePrUnit:after,
                .productItemPricesTableRow
                .productItemPricesTableMinimum:after {
                    content: none;
                }

                .productItemPricesTablePricePrUnit,
                .productItemPricesTablePricePrUnit {
                    text-align: right;
                }

                .productItemPricesTableHeader,
                .productItemPricesTableRow {
                    border-bottom: 2px solid $powderBlue;
                }

                .productItemPricesTableHeader {
                    font-size: inherit;
                    font-weight: 500;
                    color: $colorBrownishGray;
                    padding-bottom: 17px;

                    .productItemPricesTableMinimum {
                        padding: 0;
                    }
                }
                .productItemPricesTableHeader
                .productItemPricesTablePricePrUnit,
                .productItemPricesTableRow .productItemPricesTablePricePrUnit {
                    width: 95px;
                }
                .productItemPricesTableHeader
                .productItemPricesTablePricePrUnit,
                .productItemPricesTableHeader .productItemPricesTableMinimum {
                    padding: 0;
                }

                .productItemPricesTableRow {
                    font-size: 16px;
                    padding: 12px 0;

                    .productItemPricesTableMinimum {
                        padding: 0;
                        font-size: inherit;

                        .formBoxItem {
                            width: 165px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .productItemPricesTablePricePrUnit {
                        padding: 0 20px 0 0;

                        .priceRebateBtn {
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .productItemPricesTablePricePrUnitSaving {
                        padding: 0;
                    }
                }
            }

            min-width: 402px;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }

    &__price-details {
        position: relative;
        display: none;
        justify-content: flex-end;
        margin: 0 70px 15px 0;
        text-align: right;

        .productItemBuyPricesOpt,
        .productItemBuyPricesTotal,
        .productItemBuyPricesTotal .bold {
            font-size: 14px;
        }

        .priceRebateBtn {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate3d(9px, calc(-100% + 2px), 0);
            background-image: none;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            background-color: $background__info-icon;

            &:before {
                content: "i";
                color: $colWhite;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                font-size: 10px;
            }
        }

        .productItemBuyPricesOpt.hasOptions:after,
        .productItemBuyPricesOpt.open:after {
            content: "";
            position: absolute;
            top: 6px;
            right: 1px;
            background-repeat: no-repeat;
            height: 7px;
            width: 13px;
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlNvbGlkX194NDBfMngiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCINCgkgeT0iMHB4IiB2aWV3Qm94PSIyNzEgMTYwLjggNDE4LjEgMjM4LjMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMjcxIDE2MC44IDQxOC4xIDIzOC4zIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiM1QTZFODIiIGQ9Ik00ODAsMzk5LjFjLTcuNSwwLTE1LTIuOS0yMC43LTguNkwyNzkuNSwyMTAuOGMtMTEuNC0xMS40LTExLjQtMzAsMC00MS40YzExLjQtMTEuNCwzMC0xMS40LDQxLjQsMA0KCUw0ODAsMzI4LjVsMTU5LjEtMTU5LjFjMTEuNC0xMS40LDMwLTExLjQsNDEuNCwwYzExLjQsMTEuNCwxMS40LDMwLDAsNDEuNEw1MDAuNywzOTAuNkM0OTUsMzk2LjMsNDg3LjUsMzk5LjEsNDgwLDM5OS4xeiIvPg0KPC9zdmc+DQo=");
        }

        .productItemBuyPricesOpt {
            padding-right: 20px;
        }
        .productItemBuyPricesTotal {
            padding-left: 15px;
        }

        .dimedText {
            color: $colorBrownishGray !important;
        }

        @media (min-width: 992px) {
            display: flex;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: auto 0 0 0;
    }

    &__buy-form {
        display: none;
        margin: 0 30px 0 0;

        @media (min-width: 768px) {
            display: flex;
        }

        .itemUnit {
            max-width: none !important;
        }
    }

    &__add-to-cart {
        position: relative;
        display: flex;

        margin-left: 10px;

        .addToRmaCart,
        .addToCart {
            background-color: $background__btn;
            border-radius: 50%;
            width: 40px !important;
            height: 40px;
            transition: background-color 275ms cubic-bezier(0.19, 1, 0.22, 1);
            background-image: none !important;
            float: none !important;
            border: 2px solid $background__btn;

            &:before {
                content: "";
                position: absolute;
                width: 22px;
                height: 20px;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICAgIDxwYXRoIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEyLjY2NyA0bC00LjE2NiA2LjZIM3YyLjJoMS40NjZsMS44MzIgMTFoMTUuNDAybDEuODMyLTExSDI1di0yLjJoLTUuNDk4TDE1LjMyNyA0aC0yLjY2ek0xNCA1Ljc4OWwyLjk2IDQuODExSDExLjA0TDE0IDUuNzg5ek02LjY5NyAxMi44SDIxLjVsLTEuNjY1IDguOEg4LjE2NGwtMS40NjctOC44eiIvPgo8L3N2Zz4K")
                    center center no-repeat;
            }

            &:hover {
                background-color: $colWhite;

                &:before {
                    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICAgIDxwYXRoIGZpbGw9IiNmZDUxMDEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEyLjY2NyA0bC00LjE2NiA2LjZIM3YyLjJoMS40NjZsMS44MzIgMTFoMTUuNDAybDEuODMyLTExSDI1di0yLjJoLTUuNDk4TDE1LjMyNyA0aC0yLjY2ek0xNCA1Ljc4OWwyLjk2IDQuODExSDExLjA0TDE0IDUuNzg5ek02LjY5NyAxMi44SDIxLjVsLTEuNjY1IDguOEg4LjE2NGwtMS40NjctOC44eiIvPgo8L3N2Zz4K")
                        center center no-repeat;
                }
            }

            > span {
                display: none;
            }
        }
    }

    &__light-calculator {
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='30' viewBox='0 0 20 30'%3E%3Cpath fill='%23606060' fill-rule='evenodd' d='M18 23v7H2v-7h16zm-9-5v2H2v-2h7zm9 0v2h-7v-2h7zM10 7a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V8a1 1 0 0 1 1-1zm6.364-2.05l2.828 2.828a1 1 0 0 1-1.414 1.414L14.95 6.364a1 1 0 0 1 1.414-1.414zm-11.314 0a1 1 0 0 1 0 1.414L2.222 9.192A1 1 0 1 1 .808 7.778L3.636 4.95a1 1 0 0 1 1.414 0zM15 0A5 5 0 0 1 5 0h2a3 3 0 0 0 6 0h2z'/%3E%3C/svg%3E%0A") !important;
        background-position-y: 8px !important;
        color: #606060;

        margin: 0 15px 0 0 !important;
        padding-right: 15px !important;
        width: auto !important;
        background-position-x: right !important;
        text-align: right;
        text-transform: none;
        font-size: 14px;
        font-weight: 500 !important;

        @media (min-width: 768px) {
            padding-right: 30px !important;
        }

        span {
            position: relative;
            font-size: inherit;
        }

        &-text {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }

        &:hover {
            background-color: transparent;
        }
    }
}
