@import "~Utilities/variables.scss";
@import "~Utilities/colorScheme.scss";

$block: ".main-menu-link";

$color__marker-bg: $colorBloodOrange;
$color__bg: $colorOffWhite;

#{$block} {
    + #{$block} {
        margin: 0 0 0 50px;
    }

    &__link {
        position: relative;
        text-transform: uppercase;
        padding-bottom: 11px;
        z-index: 3;

        &:hover,
        &:focus {
            &:after {
                opacity: 1;
                transform: translate3d(0, 100%, 0);
            }
        }

        &:after {
            display: inline-block;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate3d(0, calc(100% - 5px), 0);
            z-index: 2;
            width: 100%;
            height: 7px;
            background-color: $color__marker-bg;
            opacity: 0;
            transition: opacity 225ms cubic-bezier(0.9, 0.03, 0.69, 0.22),
                transform 275ms cubic-bezier(0.9, 0.03, 0.69, 0.22);
        }

        &--active {
            &:after {
                opacity: 1;
                transform: translate3d(0, 100%, 0);
            }
        }

        .minified & {
            &:after {
                bottom: -6px;
            }
        }
    }

    &__dropdown {
        position: absolute;
        bottom: 36px;
        left: 0;
        transform: translate3d(0, 100%, 0);
        transition: opacity 275ms cubic-bezier(0.9, 0.03, 0.69, 0.22),
            visibility 0ms 275ms;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        background-color: $color__bg;
        box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.3);
        z-index: 1;

        &--open {
            opacity: 1;
            visibility: visible;
            transition: opacity 275ms cubic-bezier(0.9, 0.03, 0.69, 0.22),
                visibility 0ms 0ms;
        }

        .minified & {
            bottom: 7px;
        }
    }
}
