$marginDesktop: 10px;
$marginMobile: 20px;

header > h1.pageTitle {
    font-size: 34px;
    line-height: 1.18;
    color: black;
    font-weight: $boldFontWeight;
    border-bottom: none;
    padding-bottom: 40px;
    text-transform: none;

    @include breakpoint(phone) {
        padding-bottom: 43px;
        border-bottom: 1px solid $pinkishGray;
    }
}

header > h1.pageTitle,
.statusMessage {
    margin: $marginDesktop;
    @include breakpoint(phone) {
        margin: $marginMobile;
    }
}
