@import "~Utilities/colorScheme.scss";
@import "~Utilities/mixins.scss";
@import "~Utilities/variables.scss";
@import "~Utilities/snippets.scss";

$block: ".newsletterFullWidthSpot";

#{$block} {
    padding: 50px 10px;
    background-color: $powderBlue;
    color: black;
    position: relative;
    text-align: center;
    width: 100%;
    margin-top: 50px;

    @include breakpoint(phone) {
        display: flex;
        flex-direction: column;
    }

    h1 {
        font-size: $fontsize-sectionheader;
        margin-bottom: 15px;
        font-weight: 700;
    }

    .rte {
        font-size: 18px;
        font-weight: 500;
    }

    .newsletter-form {
        margin-top: 40px;

        input {
            margin: 0;
            height: 60px;
            border-radius: $border-radius;
        }

        input[type="submit"] {
            padding: 0 26px;
            @extend .bloodOrangeButton;

            @include breakpoint(phone) {
                width: 70%;
            }
        }

        .newsletter-fields {
            display: inline-block;

            @include breakpoint(phone) {
                display: block;
                width: 100%;
            }

            .newsletter-field {
                display: block;
                float: left;
                height: 40px;

                @include breakpoint(phone) {
                    display: block;
                    width: 100%;
                    height: auto;
                    margin-top: 20px;
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
