@import "~Utilities/colorScheme.scss";
$block: ".burger-button";

#{$block} {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0;

    &:hover {
        background-color: transparent;
    }

    @media (min-width: 768px) {
        display: none;
    }

    &__text {
        color: $colBlack;
        line-height: 1.5;
        margin-right: 8px;
        margin-bottom: 4px;
        text-transform: none;
        font-size: 17px;
        font-weight: 700;
    }
}
