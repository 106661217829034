@import "~Utilities/variables";
@import "~Utilities/colorScheme";

#checkout {
    padding: 20px 0px;
}

#checkoutWrapper {
    background: $offWhite;
    overflow: hidden;
    color: $newBlack;
    margin: 10px;

    .linkSpan {
        text-decoration: none;
        cursor: pointer;
        text-transform: capitalize;
    }

    .dispPhoneOnly {
        @include breakpoint(tabletdesktop) {
            display: none;
        }
    }

    .dispTabletDesktopOnly {
        @include breakpoint(phone) {
            display: none;
        }
    }

    .smallHeader {
        font-weight: $boldFontWeight;
        font-size: 16px;
        line-height: 1;
        color: #242424;
    }

    button {
        text-transform: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.29;
        color: #fd5101;
        background: transparent;
        padding: 0 10px 0;
    }
}

#orderMainInfoFields,
#orderMainInfoMoreFields {
    .dispPhoneOnly {
        @include breakpoint(phone) {
            display: flex;
            flex-direction: column;
            > * {
                margin-bottom: 25px;
            }
        }
    }

    .dispTabletDesktopOnly {
        @include breakpoint(tabletdesktop) {
            display: flex;
            width: 100%;
            > .column {
                break-inside: avoid;
                display: flex;
                flex-direction: column;
                width: 33%;
                padding: 0 15px;
                > :nth-child(n + 2) {
                    margin-top: 30px;
                }
            }
        }
    }

    .smallHeaderWithLink {
        display: flex;
        justify-content: space-between;
    }

    select,
    input {
        border: 1px solid $powderBlue;
        box-shadow: none;
        margin: 10px 0 0;
    }
}

#orderMainInfoMoreFields {
    margin-top: 30px;
    .dispPhoneOnly {
        margin-top: 30px;
    }
}

#orderMainInfoMoreToggle {
    width: 100%;
    text-align: center;
    font-weight: $mediumFontWeight;
    margin-top: 40px;

    .linkSpan {
        display: flex;
        justify-content: center;
        > p {
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            margin: 0 0 0 8px;
            &:hover,
            &:focus {
                color: $colorBloodOrange;
            }
        }
    }
}

.deliveryAddressExplanation {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    user-select: none;
    color: $colorBrownishGray;
}

.deliveryAddressEditableFields {
    & > div {
        margin-bottom: 30px;
    }
    & .dispPhoneOnly {
        margin-top: 30px;
    }
}

.deliveryAddress {
    display: flex;
    flex-direction: column;
    > .deliveryAddressLabel {
        width: 148px;
        height: 16px;
        font-weight: $boldFontWeight;
        font-size: 16px;
        line-height: 1;
        color: #242424;
    }

    > .deliveryAddressNonEdit {
        font-size: 16px;
        line-height: 1.3;
        color: #242424;
        margin-top: 10px;
    }
}

.deliveryAddressSubtext {
    margin-top: 10px;
}

.accountSummary {
    .property {
        display: flex;
        justify-content: space-between;
        .propertyName {
            font-size: 14px;
            line-height: 1.86;
            color: $colorBrownishGray;
        }
        .propertyValue {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.86;
            text-align: right;
            color: $newBlack;
        }
    }
}

@include breakpoint(phone) {
    .deliveryAddressSubtext {
        margin-top: 30px;
    }
    .delivery-address-subtext--half-col {
        float: left;
        width: 50%;
        &:only-child {
            width: 100%;
        }
    }
    #checkout {
        padding: 20px 10px;
    }
}
