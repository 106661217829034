$colorAddIcon: $accentColorNew;
$colorLabel: $colBlack;
$colorLabelFocus: $accentColorNew;
$fontSize: $baseFontSize;
$fontSizeAddIcon: $h1FontSize;

.showMoreContainer {
    text-align: center;
}

.showMoreContainer > a,
.showMoreContainer > button {
    text-decoration: none !important;
    background: none;
    cursor: pointer;

    & > .iconAdd {
        margin: auto 10px;
        color: $colorAddIcon;
        font-size: $fontSizeAddIcon;
    }

    & > .label {
        transition: color 0.1s;
        font-size: $fontSize;
        color: $colorLabel;
        font-weight: $boldFontWeight;
        text-transform: none;
        &:hover {
            color: $colorLabelFocus;
        }
    }
}
