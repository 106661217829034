$block: ".filters";

$background__color: $colorOffWhite;
$font__color: $color__black;
$color__close: $colorBloodOrange;

#{$block} {
    color: $font__color;
    z-index: 1020;
    @media (max-width: 1279px) {
        background-color: $background__color;
    }

    .boxContent {
        background-color: $background__color;
        border: 0;

        h2.filters__heading {
            font-size: 22px;
            margin: 0 0 30px;
            padding: 0;
            text-transform: none;
            font-weight: 500;
            color: $font__color;
        }
    }

    &__content {
    }

    &__heading {
    }

    &__options {
    }

    &__clear {
        font-size: 16px;
        display: inline-block;
        cursor: pointer;
        transition: color 175ms cubic-bezier(0.075, 0.82, 0.165, 1);

        svg {
            width: 20px;
            height: 20px;
            transform: translate3d(0, 4px, 0);

            path {
                fill: $color__close !important;
            }
        }

        &:hover {
            color: $color__close;
        }
    }

    &__close {
        display: block;
        position: relative;
        background: transparent;
        font-weight: 700;
        color: $font__color;
        text-transform: none;
        padding: 0 27px 0 0;
        font-size: 16px;
        margin: 0 0 44px auto;

        &:after {
            content: "";
            position: absolute;
            top: 7px;
            right: 0;
            width: 22px;
            height: 22px;
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICAgIDxwYXRoIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIwLjc3IDZsLTcuMjcgNy4yNzFMNi4yMyA2IDUgNy4yMjlsNy4yNyA3LjI3MUw1IDIxLjc3IDYuMjMgMjNsNy4yNy03LjI3TDIwLjc3IDIzIDIyIDIxLjc3bC03LjI3LTcuMjdMMjIgNy4yM3oiLz4KPC9zdmc+Cg==");
        }

        &:hover {
            background: transparent;
        }

        @media (min-width: 1280px) {
            display: none;
        }
    }
}

@media (max-width: 1279px) {
    .filters.visibleOffCanvas .boxContent {
        background: transparent;
        border: none;
        padding: 0;
        margin: 35px 15px 35px 15px;
    }
    #filters:not(.filters) {
        background-color: #5a6e82;
        h3 {
            color: white;
        }
        .searchInput {
            width: 100%;
            input {
                height: 40px;
            }
        }
        .searchButton {
            width: 36px;
        }

        .filterSection.accOpen > h3:before {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlNvbGlkX194NDBfMngiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCINCgkgeT0iMHB4IiB2aWV3Qm94PSIyNzEgMTYwLjggNDE4LjEgMjM4LjMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMjcxIDE2MC44IDQxOC4xIDIzOC4zIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik01MDAuNywxNjkuNGwxNzkuOCwxNzkuOGMxMS40LDExLjQsMTEuNCwzMCwwLDQxLjRjLTExLjQsMTEuNC0zMCwxMS40LTQxLjQsMEw0ODAsMjMxLjVMMzIwLjksMzkwLjYNCgljLTExLjQsMTEuNC0zMCwxMS40LTQxLjQsMGMtMTEuNC0xMS40LTExLjQtMzAsMC00MS40bDE3OS44LTE3OS44YzUuNy01LjcsMTMuMi04LjYsMjAuNy04LjZDNDg3LjUsMTYwLjgsNDk1LDE2My43LDUwMC43LDE2OS40eiINCgkvPg0KPC9zdmc+DQo=");
        }
        .filterSection.accClosed > h3:before {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IlNvbGlkX194NDBfMngiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCINCgkgeT0iMHB4IiB2aWV3Qm94PSIyNzEgMTYwLjggNDE4LjEgMjM4LjMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMjcxIDE2MC44IDQxOC4xIDIzOC4zIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik00ODAsMzk5LjFjLTcuNSwwLTE1LTIuOS0yMC43LTguNkwyNzkuNSwyMTAuOGMtMTEuNC0xMS40LTExLjQtMzAsMC00MS40YzExLjQtMTEuNCwzMC0xMS40LDQxLjQsMA0KCUw0ODAsMzI4LjVsMTU5LjEtMTU5LjFjMTEuNC0xMS40LDMwLTExLjQsNDEuNCwwYzExLjQsMTEuNCwxMS40LDMwLDAsNDEuNEw1MDAuNywzOTAuNkM0OTUsMzk2LjMsNDg3LjUsMzk5LjEsNDgwLDM5OS4xeiIvPg0KPC9zdmc+DQo=");
        }
    }
}

@media (max-width: 1279px) and (min-width: 768px) {
}
