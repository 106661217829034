:root {
    --default-color: rgb(77, 91, 111);
    --secondary-color: rgba(138, 156, 171);
    --bg-color-success: rgba(36, 147, 3);
    --fg-color-success: #fff;
}

.crop-wrap {
    min-height: 500px !important;
    max-height: 90%;
}

.cropper-comp {
    max-height: 70vh !important;
}

.uploadhandler-wrapper {
    height: 100% !important;
    max-height: 90vh;
    overflow: auto;
}

.Image2Quote {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 600px;
    height: 100%;

    text-align: initial;
}

.Image2Quote .uploadhandler-wrapper {
    /* height: 90vh !important; */
    /* height: 100%; */
    /* height: 90vh; */
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 1;
}

.Image2Quote-content {
    flex: 1;
    /* border: 1px solid var(--default-color); */
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;
}

.i2q-heading2 {
    color: var(--default-color);
    font-weight: normal;
    text-transform: uppercase;
    padding-bottom: 0.8rem;
    border-bottom: 3px solid var(--default-color);
}

.i2q-heading3 {
    color: var(--default-color);
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.i2q-navi-backlink {
    color: var(--default-color);
}

.btn-secondary {
    background-color: var(--secondary-color);
}

.btn:disabled {
    background-color: #aaa;
    color: #fff;
}

.btn-success {
    background-color: var(--bg-color-success);
    color: #fff;
}

.btn-group .btn:not(:first-of-type) {
    margin-left: 1rem;
}

.btn-helper-text {
    padding: 0 1rem;
    font-style: italic;
}

.i2q-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* ############## Symboltext Component */
.i2q-symboltext-form {
    background-color: #fff;
    margin-right: auto;
    margin-bottom: 2rem;
}

.i2q-symboltext-form .i2q-text-input ~ .btn {
    margin-left: 1rem;
}

.i2q-symboltext-form .i2q-text-input,
.i2q-symboltext-form .btn {
    display: inline-block;
}

.i2q-text-input {
    border: 1px solid var(--default-color);
}

.i2q-select-input {
    border: 1px solid var(--default-color);
    font-size: 0.8rem;
    /*same padding as btn but offset for border-width*/
    padding: calc(0.5rem - 1px);
    border-radius: 0;
    margin-right: 0.3rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Remove default arrow */
    /* background-image: url(...);  //Add Custom Arrow here */
}

/* -- adjustments in Dropdown to fit in Lemu-Styles */
.single-symbol-form .i2q-select-input,
.single-symbol-form .input-group {
    display: flex;
}

.single-symbol-form .input-group {
    display: flex;
}

/* unset LeMu input style within dropdown */
.i2q-select-input input {
    height: initial;
}
/* -- End of adjustments in Dropdown */

.i2q-form-helper-text {
    display: block;
    margin: 0.5rem 0;
    font-style: italic;
}

select::-ms-expand {
    /* CAUTION: Internet Explorer hack */
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

.i2q-symboltext-list {
    list-style: none;
    margin: 1rem 0 0;
    padding: 0;
    margin-right: auto;
}

.i2q-symboltext-listitem {
    list-style: none;
    background-color: #fff;
    padding: 0.5rem 2rem;
}
.i2q-symboltext-list > .i2q-symboltext-listitem:nth-of-type(odd) {
    background-color: #cccccc;
}
