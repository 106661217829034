$fontsize-main: 16px;
$fontsize-sectionheader: 50px;
$fontsize-insidebox-header: 22px;

/************************************************/
/*			BOURBON SETTINGS                    */
/************************************************/

$output-bourbon-deprecation-warnings: false !default;

/************************************************/
/*			FONTS								*/
/************************************************/

/* GENERAL */
$baseFontFamily: "Inter", Arial, Helvetica, Sans-Serif;

$lightFontWeight: 300;
$baseFontWeight: 400;
$mediumFontWeight: 500;
$boldFontWeight: 700;

/* SIZES */
$microFontSize: 8px;
$tinyFontSize: 10px;
$smallFontSize: 12px;
$mediumFontSize: 13px;
$baseFontSize: 14px;
$largeFontSize: 16px;
$megaFontSize: 36px;
$h6FontSize: 9px;
$h5FontSize: 10px;
$h4FontSize: 12px;
$h3FontSize: 14px;
$h2FontSize: 18px;
$h1FontSize: 24px;

/************************************************/
/*			GRID								*/
/************************************************/

$columns: 16;
$gutter: 20px;
$columnWidth: 60px;
$tablecolumns: 12;

$allColumns: $columnWidth * $columns; // 960px
$allGutters: $gutter * $columns; // 320px
$fullWidth: $allColumns + $allGutters; // 1280px
$tabletMinWidth: 768px;
$tabletMaxWidth: 960px;

/************************************************/
/*          Z-INDEXES                           */
/************************************************/

$zindex-basic: 1000 !default;
$zindex-dropdown: 1010 !default;
$zindex-overlay: 1020 !default;
$zindex-tooltip: 1030 !default;
$zindex-max: 1040 !default;

// MISC
$border-radius: 6px;
