$block: ".search-form";

$font__color: $color__black;
$backgorund__btn: $colorBloodOrange;

#{$block} {
    color: $font__color;
    margin: 0 0 25px 0;

    &__label {
        display: block;
        float: none;
        font-weight: 500;
        margin-bottom: 12px;
    }

    &__field {
        position: relative;
    }

    &__input-container {
        display: block;
        width: 100%;
        border-radius: 6px;
        background-color: white;

        > input {
            float: none;
        }
    }

    &__input {
        height: 55px;
        border: none;
        box-shadow: none;
        width: calc(100% - 30px);
        background-color: transparent;
    }

    &__btn {
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translate3d(0, -50%, 0);
        background-color: $backgorund__btn;
        height: 43px;
        width: 43px;
        border-radius: 6px;
        border: solid 2px $backgorund__btn;
        transition: background-color 275ms cubic-bezier(0.19, 1, 0.22, 1);

        &:before {
            content: "";
            background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICAgIDxwYXRoIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTI0IDIyLjU4NkwyMi41ODYgMjRsLTQuMjQzLTQuMjQzIDEuNDE0LTEuNDE0TDI0IDIyLjU4NnpNMTIgMThjLTMuMzA5IDAtNi0yLjY5MS02LTZzMi42OTEtNiA2LTYgNiAyLjY5MSA2IDYtMi42OTEgNi02IDZ6bTAtMTRhOCA4IDAgMSAwIDAgMTYgOCA4IDAgMCAwIDAtMTZ6Ii8+Cjwvc3ZnPgo=")
                center center no-repeat;
            background-size: 20px 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            height: 20px;
            width: 20px;
        }

        &:hover {
            background-color: $colWhite;

            &:before {
                background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICAgIDxwYXRoIGZpbGw9IiNmZDUxMDEiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTI0IDIyLjU4NkwyMi41ODYgMjRsLTQuMjQzLTQuMjQzIDEuNDE0LTEuNDE0TDI0IDIyLjU4NnpNMTIgMThjLTMuMzA5IDAtNi0yLjY5MS02LTZzMi42OTEtNiA2LTYgNiAyLjY5MSA2IDYtMi42OTEgNi02IDZ6bTAtMTRhOCA4IDAgMSAwIDAgMTYgOCA4IDAgMCAwIDAtMTZ6Ii8+Cjwvc3ZnPgo=")
                    center center no-repeat;
                background-size: 20px 20px;
            }
        }
    }

    &--small {
        #{$block} {
            &__input {
                height: 40px;
            }

            &__btn {
                right: 3px;
                height: 30px;
                width: 30px;
            }
        }
    }
}
