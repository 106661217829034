@import "~Utilities/variables.scss";
@import "~Utilities/colorScheme.scss";

$block: ".main-menu";

$color__font: $colWhite;

#{$block} {
    display: none;
    color: $color__font;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;

    @media (min-width: 768px) {
        display: block;
    }

    a:link,
    a:active,
    a:visited {
        display: inline-block;
        color: inherit;
        text-decoration: none;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
    }

    &__list {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
