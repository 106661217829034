$block: ".off-canvas-trigger";

#{$block} {
    position: absolute;
    top: 25px;
    right: 8px;
    transition: right 300ms cubic-bezier(0.9, 0.03, 0.69, 0.22);

    svg {
        path {
            stroke: #fff !important;
            transition: stroke 125ms cubic-bezier(0.9, 0.03, 0.69, 0.22) 200ms;
        }
    }

    .show-off-canvas & {
        right: calc(-100% + 9px);

        svg {
            path {
                stroke: #000 !important;
                transition: stroke 125ms cubic-bezier(0.9, 0.03, 0.69, 0.22);
            }
        }
    }
}
