.customer-team__image-wrapper {
    display: flex;
    overflow: hidden;
    min-height: 180px;
}

.customer-team__image {
    width: 100%;
    min-height: 180px;
}

.customer-team__details {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    word-break: break-all;
}

.customer-team__details--main {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    height: 100%;
}

.customer-team__details--contact {
    color: $newBlack;
    line-height: 1.5;
}

.customer-team__position {
    line-height: 22px;
    color: $colorBrownishGray;
    width: inherit;
}

.customer-team__name {
    font-weight: 700;
    line-height: 25px;
}

.customer-team__link {
    text-align: center;
    font-weight: 700;
    a {
        text-decoration: none;
    }
}

.customer-team__link-icon {
    vertical-align: middle;
}

.customer-team__contact-row {
    display: flex;
    align-items: center;
}

.customer-team__icon--phone {
    padding: 2px;
}

.customer-team__icon--email,
.customer-team__icon--phone {
    min-width: 28px;
}

.customer-team__contact-label {
    margin-left: 20px;
}

.customer-team-list {
    display: flex;
    flex-flow: row wrap;
    padding: 0 20px 20px;
    justify-content: center;
    @include breakpoint(phone) {
        padding: 20px;
        margin-bottom: 40px;
        flex-direction: column;
    }
}

.customer-team-list__spot {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 20px 20px 0;
    min-width: 350px;
    font-size: $largeFontSize;
    .customer-team__details--contact {
        font-weight: $boldFontWeight;
    }
    .customer-team__details--main {
        min-height: 100px;
        justify-content: space-between;
    }
    .customer-team__name {
        font-size: 16px;
        color: $newBlack;
    }
    .customer-team__details {
        background: $colGray02;
        min-height: 160px;
    }
    .customer-team__position {
        margin: 20px 0 0;
    }
    @include breakpoint(phone) {
        width: auto;
        margin: 0 0 20px;
    }
}

.customer-team__spot {
    background-color: $colorOffWhite;
    border: 0;
    height: 100%;
    padding: 0 0 15px;
    min-height: 368px;
    max-width: 450px;
    .customer-team__name {
        font-size: $largeFontSize;
        text-transform: uppercase;
    }
    .customer-team__details {
        padding: 10px 30px;
    }
    .customer-team__position {
        margin: 0;
    }
    @include breakpoint(desktop) {
        max-height: 368px;
        overflow-y: auto;
    }
    @include breakpoint(phone) {
        margin: 20px;
    }
}

@include breakpoint(tabletdesktop) {
    .customer-team-list__spot {
        max-width: 300px;
    }
}
