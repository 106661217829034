$block: ".btn";

#{$block} {
    width: 100%;
    text-transform: none;
    font-weight: 400;
    transition: border 225ms cubic-bezier(0.075, 0.82, 0.165, 1),
        color 225ms cubic-bezier(0.075, 0.82, 0.165, 1);
    line-height: 1.1;
    height: auto;

    svg {
        width: 16px;
        height: 16px;

        path,
        circle {
            transition: fill 225ms cubic-bezier(0.075, 0.82, 0.165, 1),
                stroke 225ms cubic-bezier(0.075, 0.82, 0.165, 1);
        }
    }

    &--simple {
        background-color: $colWhite;
        border: solid 1px $color__pinkish-gray;
        border-radius: 4px;
        color: $color__black;
        padding: 12px 20px 14px 20px;

        &:hover {
            background-color: $colWhite !important;
            border: solid 1px $colorBloodOrange;
            color: $colorBloodOrange;

            svg {
                path {
                    fill: $colorBloodOrange;
                }

                circle {
                    stroke: $colorBloodOrange;
                }
            }
        }
    }

    &--filter {
        margin-bottom: 10px;

        svg {
            transform: translate3d(0, 3px, 0);
        }

        @media (min-width: 1280px) {
            display: none;
        }
    }
}
