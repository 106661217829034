@import "~Utilities/variables.scss";
@import "~Utilities/colorScheme.scss";
@import "~Utilities/mixins.scss";

$block: ".multi-level-menu-list";
$color__font: $colBlack;

#{$block} {
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    max-height: 0;
    transition: max-height 275ms cubic-bezier(0.4, 0, 0.2, 1);

    &--expanded {
        max-height: 100vh;
        opacity: 1;
    }
    &--level1 {
        margin: 0;
        max-height: none;

        &.borderTop > li:first-child > .multi-level-menu-link__container {
            padding-top: 25px;
            border-top: solid 2px $colBlack;
            margin-top: 10px;
        }

        > li {
            margin-bottom: 5px;

            > div {
                border-bottom: solid 2px $colBlack;
                margin-bottom: 26px;

                > a:link,
                a:active,
                a:visited {
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 1.14;
                    margin-right: 15px;
                    padding: 0 0 10px 0;
                    color: $color__font;
                    @include breakpoint(tablet) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
